module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@4.22.0_gatsby@4.22.0_react-dom@18.0.0_react@18.0.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7V0XH93DBV","",""],"gtagConfig":{"optimize_id":"","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@5.22.0_babel-plugin-styled-components@2.0.7_gatsby@4.22.0_rea_jqxirpeemioi4rqovt3ukkhwfe/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"minify":false,"transpileTemplateLiterals":false,"pure":true,"fileName":true,"namespace":"","topLevelImportPaths":[],"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.22.0_babel-eslint@10.1.0_react-dom@18.0.0_react@18.0.0_typescript@4.8.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
