import { Auth } from "aws-amplify";
import useSWR, { Fetcher, useSWRConfig } from "swr";
import { v4 as uuidv4 } from "uuid";
// import { useUtmStore } from './useUtmCodes';
// import { trackEvent } from '../../services/tracking';
import { navigate, withPrefix } from "gatsby";
import { trackMetric } from "../../services";
import { handleSignUpErrors } from "../../services/auth";
import { SignOutOptions, SignUpProps, UseAuthOptions, User } from "../../types";

const eventId = process.env.GATSBY_EVENT_ID || "";

const fetcher: Fetcher<User | undefined> = async () => {
  return Auth.currentAuthenticatedUser();
};

// With capital letter and special characters, and returns string
export const generatePassword = () =>
  uuidv4().replace(/\d{1}/, "G").replace(/\d{1}/, "v");

export const useUser = (options?: UseAuthOptions) => {
  const { cache } = useSWRConfig();
  const { data: user, error } = useSWR("user", fetcher);

  // const utmCodes = useUtmStore((state) => state.utmCodes);
  const loading = !user && !error;
  const loggedOut = error && error === "The user is not authenticated";

  // TODO: CONFIRM THIS WORKS
  if (loggedOut && options?.redirect) {
    navigate(withPrefix(options.redirect), { replace: true });
  }

  const signOut = async (options?: SignOutOptions) => {
    // TODO: WHAT IS THIS DOING?
    cache.delete("user");
    await Auth.signOut();
    if (options?.redirect) {
      navigate(options.redirect);
    }
  };

  const logInWithEmail = async (email: string) => {
    try {
      const formatEmail = email;
      const params = {
        username: formatEmail,
        password: "",
      };

      const user = await Auth.signIn(params);
      // const userGroups =
      //   user.signInUserSession?.accessToken?.payload["cognito:groups"];

      // if (!userGroups?.includes(eventId)) {
      //   signOut();
      //   throw new Error(
      //     `Sorry, you do not have access to this event, please register!`
      //   );
      // }

      await trackMetric({
        metricType: "login",
        payload: {
          href: window?.location?.href,
          email: formatEmail,
          eventId,
          // TODO: DESTRUCTURE UTM_CODE HERE
        },
      });
    } catch (error) {
      handleSignUpErrors(error);
    }
  };

  const signUp = async (data: SignUpProps) => {
    const email = data.email;
    const params = {
      username: email,
      password: generatePassword(),
      clientMetadata: {
        eventId,
        groupName: eventId,
      },
    };

    try {
      await Auth.signUp(params);
      await trackMetric({
        metricType: "registration",
        payload: {
          href: window?.location?.href,
          eventId,
          ...params,
          // TODO: DESTRUCTURE UTM_CODE HERE
        },
      });
    } catch (error: any) {
      if (error?.code !== "UsernameExistsException") {
        handleSignUpErrors(error);
      }
    }
    if (data?.autoLogin) {
      await logInWithEmail(email);
    } else {
      console.warn(
        `User exists but autoLogin is false. Set to true to log user in`
      );
    }
  };

  return {
    user,
    loggedOut,
    loading,
    logInWithEmail,
    signOut,
    signUp,
  };
};

export default useUser;
